import 'core-js/stable'
import 'regenerator-runtime/runtime'

import postal from 'postal'
import anime from 'animejs/lib/anime.es.js';
import * as Routes from '../routes';

// easy accessibility
window.postal = postal
window.anime = anime
window.Routes = Routes
